
.top-bar {
    display: flex;
    width: 100%;
    top: 25px;
    gap: 50px;
    flex-direction: row-reverse;
    /* justify-content: flex-end; */
    align-items: center;
    position: absolute; 
}